'use strict';

// IMPORTS

import { Lmnt } from './Lmnt';
import { LmntButtonSubmit } from './LmntButtonSubmit';
import { LmntSelect } from './LmntSelect';

export class LmntFormSelector extends Lmnt {

    private selects : LmntSelect[];
    private currentSelect : LmntSelect;
    private buttonSubmit : LmntButtonSubmit;

    // CONSTRUCTOR

    constructor(selector : string | HTMLFormElement) {
        super(selector);

        // set selects
        this.selects = [];
        let lmntSelect : LmntSelect;
        this.lmnt.querySelectorAll('select').forEach(select => {
            lmntSelect = new LmntSelect(select);
            lmntSelect.setEventListener('change', this.optionSelected.bind(this));
            this.selects.push(lmntSelect);
        });

        this.buttonSubmit = new LmntButtonSubmit('#productssifter button');
        this.buttonSubmit.setEventListener('click', this.setLink.bind(this));
    }

    // METHODS

    optionSelected(ev : Event) {
        this.currentSelect = new LmntSelect(ev.currentTarget as HTMLSelectElement);
        
        this.selects.forEach(select => {    
    
            if (select.depth > this.currentSelect.depth) {

                if (select.depth == this.currentSelect.depth + 1) {
                    select.hideUnnecessaryOptions(this.currentSelect.currentOption.idCategory);
                    
                    if (select.hasOptionsAvailable()) {
                        select.setDefaultOption();
                        select.enable();
    
                    } else {
                        select.setDefaultOption();
                        select.disable();
                    }
    
                } else {
                    select.setDefaultOption();
                    select.disable();
                }
            } 
    
            this.buttonSubmit.setStatus(this.selects);
        });
    }

    setLink(e : Event) {
        this.setAction(this.currentSelect.currentOption.idCategory + '-');
    }

    setAction(s : string) {
        this.lmnt.setAttribute('action', s);
    }
}
