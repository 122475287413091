'use strict';

// IMPORTS

import { Lmnt } from './Lmnt';
import { LmntSelect } from './LmntSelect';

export class LmntButtonSubmit extends Lmnt {

    // CONSTRUCTOR

    constructor(selector : string | HTMLButtonElement) {
        super(selector);

        this.lmnt.disabled = true;
    }

    // METHODS

    enable() {
        this.lmnt.disabled = false;
    }

    disable() {
        this.lmnt.disabled = true;
    }

    setStatus(selects : LmntSelect[]) : boolean {

        for (let i = 0; i < selects.length; i++) {

            // check if looping last existent select OR if next select is hidden
            if (selects[i + 1] === undefined || selects[i + 1].isHidden()) {

                // check if selected option is valid
                if (selects[i].getValue() !== 'none') {
                    this.enable();
                    return true;

                // last existent select OR next select is hidden, but not valid option
                } else {
                    this.disable();
                    return false;
                }
            }
        }
    }

    setEventListener(action : string, callback : EventListenerOrEventListenerObject) {
        this.lmnt.addEventListener(action, callback);
    }
}
