'use strict';

// IMPORTS

import { Lmnt } from './Lmnt';

export class LmntFormFinder extends Lmnt {

    // PROPERTIES
    private inputIdCategory : HTMLInputElement;
    private pathLanguage : string;

    // CONSTRUCTOR

    constructor(selector : string | HTMLFormElement) {
        super(selector);

        // get language from url
        if (location.pathname.includes('/en/')) {
            this.pathLanguage = 'en';
        } else if (location.pathname.includes('/it/')) {
            this.pathLanguage = 'it';
        } else {
            throw new Error('Language not found in url');
        }

        this.inputIdCategory = document.querySelector('#finder input[type=number]')!;
        this.inputIdCategory.value = LmntFormFinder.getCategoryId().toString();

        if (this.pathLanguage === 'en') {
            (this.lmnt as HTMLFormElement).action = 'search';
        } else if (this.pathLanguage === 'it') {
            (this.lmnt as HTMLFormElement).action = 'ricerca';
        }
    }

    // METHODS

    public static getCategoryId() : number {
        // get id from location.pathname
        const path = location.pathname;
        const pathParts = path.split('/')[2];
        const id = pathParts.split('-')[0];
        return parseInt(id);
    }
}
