'use strict';

import { LmntFormSelector } from './classes/LmntFormSelector';
import { LmntFormFinder } from './classes/LmntFormFinder';

if (document.getElementById('productssifter'))
{
    if (['/en/', '/it/'].includes(window.location.pathname)) {
        const form : LmntFormSelector = new LmntFormSelector('#selector');
    } else if (LmntFormFinder.getCategoryId() > 0) {
        const form : LmntFormFinder = new LmntFormFinder('#finder');
        require('./showProductsAjax');
    }
}

