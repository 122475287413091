'use strict';

// IMPORTS

import { Lmnt } from './Lmnt';

export class LmntOption extends Lmnt {

    // PROPERTIES

    public idCategory : number;
    public idParent : number;
    public isDefault : boolean;
    public nameCategory : string;

    // CONSTRUCTORS
    
    constructor(selector : string | HTMLOptionElement) {
        super(selector);

        // check if the option is the default one
        if (this.lmnt.getAttribute('value') === 'none') {
            this.idCategory = 0;
            this.idParent = 0;
            this.isDefault = true;
            
        } else {

            // set the idCategory property
            if (this.lmnt.getAttribute('value')) {
                this.idCategory = Number(this.lmnt.getAttribute('value'));
            } else {
                throw '\n\nAttribute value for option \"' + this.lmnt + '\" is not valid or does not exists.';
            }

            // set the idParent property
            if (this.lmnt.dataset.idParent) {
                this.idParent = Number(this.lmnt.dataset.idParent);
            } else {
                throw '\n\nAttribute value for option \"' + this.lmnt + '\" is not valid or does not exists.';
            }

            this.isDefault = false;
            this.nameCategory = this.lmnt.innerHTML;
        }
    }

    // METHODS

    hide() {

        // check that option is not the default one
        if (!this.isDefault) {
            this.lmnt.remove();
        }
    }

    show() {
        this.lmnt = document.createElement('option');
        this.lmnt.setAttribute('value', String(this.idCategory));
        this.lmnt.dataset.idParent = String(this.idParent);
        this.lmnt.innerHTML = this.nameCategory;
    }

    getValue() {
        return this.lmnt.getAttribute('value');
    }

    setInnerHTML(s : string) {
        this.lmnt.innerHTML = s;
    }
}
