'use strict';

// IMPORTS

import { Lmnt } from './Lmnt';
import { LmntOption } from './LmntOption';

export class LmntSelect extends Lmnt {

    public depth : number;
    public currentOption : LmntOption;
    public options : LmntOption[];

    constructor(selector : string | HTMLSelectElement) {
        super(selector);

        // set the depth property
        if (this.lmnt.dataset.depth) {
            this.depth = Number(this.lmnt.dataset.depth);
        }

        // set the options property
        this.options = [];
        this.lmnt.querySelectorAll('option').forEach(lmntOption => {
            
            // set the currentOption property
            if (lmntOption.getAttribute('value') === this.lmnt.value) {
                this.currentOption = new LmntOption(lmntOption);
            }

            this.options.push(new LmntOption(lmntOption));
        });
    }

    hideUnnecessaryOptions(currentOptionIdCategory : number) {
        this.options.forEach(option => {
            if (option.idParent != currentOptionIdCategory) {
                option.hide();
            } else {
                option.show();
            }
        });
    }

    setDefaultOption() {
        this.lmnt.value = 'none';
    }

    setSelectCategory() {
        this.setDefaultOption();
        const s = document.querySelector('#productssifter option[selected]').innerHTML;
        this.currentOption.setInnerHTML(s);
    }

    setBlank() {
        this.setDefaultOption();
        this.currentOption.setInnerHTML('');
    }

    hasOptionsAvailable() {
        const test = this.lmnt.querySelectorAll('option[hidden=""]');

        if (test.length == this.options.length - 1) {
            return false;
        }
        return true;
    }

    getValue() {
        return this.lmnt.value;
    }

    isHidden() {
        if (this.lmnt.hasAttribute('hidden')) {
            return true;
        }
        
        return false;
    }

    setEventListener(action : string, callback : EventListenerOrEventListenerObject) {
        this.lmnt.addEventListener(action, callback);
    }

    enable() {
        this.lmnt.removeAttribute('disabled');
    }

    disable() {
        this.lmnt.setAttribute('disabled', '');
    }

}
