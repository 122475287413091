
document.addEventListener('DOMContentLoaded', () => {

    const input = document.querySelector('#finder input') as HTMLInputElement;
    const productsList = document.querySelector('#productssifter div.ajax-results') as HTMLDivElement;

    let typingTimer : any;
    let doneTypingInterval = 250;

    // on keydown, show loading spinner
    input.addEventListener('keydown', () => {
        console.info('keydown');
        const search_string = input.value.trim();
        if (search_string) {
            productsList.innerHTML = getSearchingAnimation();
        }
    });

    //on keyup, start the countdown
    input.addEventListener('keyup', () => {
        const search_string = input.value.trim();
        clearTimeout(typingTimer);
        if (search_string) {
            typingTimer = setTimeout(() => {
                sendAjaxRequest(search_string);
            }, doneTypingInterval);
        } else {
            productsList.innerHTML = '';
        }
    });

    //user is "finished typing," do something
    function sendAjaxRequest (search_string : string) {
        let url = '/module/productssifter/GetProductsAjax?ajax=1';    
        url += '&search_string=' + search_string;
        url += '&id_category=' + getCategoryIdFromUrl();

        const xhr = new XMLHttpRequest();

        xhr.open('POST', url, true);

        xhr.onload = () => {

            if (productsList) {
                productsList.innerHTML = xhr.response;
            } else {
                console.error('productsList is null');
            }
        }
        
        xhr.send();
    }

    document.addEventListener('click', (event) => {
        if (!productsList.contains(event.target as Node)) {
            productsList.innerHTML = '';
        }
    });
});

function getCategoryIdFromUrl() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const idCategory = pathSegments[2].split('-')[0] || null;
    return idCategory;
}


function getSearchingAnimation(): string {
    let el = document.createElement('div');
    el.classList.add('dot-animation');

    for (let i = 0; i < 3; i++) {
        let dot = document.createElement('span');
        dot.classList.add('dot');
        dot.innerHTML = '.';
        el.appendChild(dot);
    }

    return el.outerHTML;
}